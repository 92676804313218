import {
  AgoraRTCClient,
  AgoraStreamSpec,
  DeviceInfo,
} from "./agora-rtc-client";
// import { eventRoomStore, EventRoomStore } from "../stores/event";
import { isEmpty } from "lodash";
import EventEmitter from "events";
import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraStream } from "./types";

export const createAudioStream = (microphoneId: string) => {
  const streamInitPromise = new Promise((resolve, reject) => {
    const stream = AgoraRTC.createStream({
      video: false,
      audio: true,
      microphoneId,
    });
    stream.init(
      () => resolve(stream),
      (err: any) => reject(err)
    );
  });
  return streamInitPromise;
};

export const createVideoStream = (cameraId: string) => {
  const streamInitPromise = new Promise((resolve, reject) => {
    const stream = AgoraRTC.createStream({
      video: true,
      audio: false,
      cameraId,
    });
    stream.init(
      () => resolve(stream),
      (err: any) => reject(err)
    );
  });
  return streamInitPromise;
};

async function getConnectedDevices(type: string) {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter((device) => device.kind === type);
}

export const getCameraDevices = async () => {
  const agoraVideoStream = AgoraRTC.createStream({ video: true, audio: false });
  try {
    const requestVideoPermissionPromise = new Promise((resolve, reject) => {
      agoraVideoStream.init(
        () => resolve(null),
        (err: any) => reject(err)
      );
    });
    await requestVideoPermissionPromise;
    let videoDevices = await getConnectedDevices("videoinput");
    return videoDevices.map((device: any, idx: number) => ({
      deviceId: device.deviceId,
      text: device.label ? device.label : `camera-${idx}`,
    }));
  } catch (err) {
    console.warn("create video stream failed!", err);
    throw err;
  } finally {
    agoraVideoStream.close();
  }
};

export const getMicrophoneDevices = async () => {
  const agoraAudioStream = AgoraRTC.createStream({ video: false, audio: true });
  try {
    const requestVideoPermissionPromise = new Promise((resolve, reject) => {
      agoraAudioStream.init(
        () => resolve(null),
        (err: any) => reject(err)
      );
    });
    await requestVideoPermissionPromise;
    let videoDevices = await getConnectedDevices("audioinput");
    return videoDevices.map((device: any, idx: number) => ({
      deviceId: device.deviceId,
      text: device.label ? device.label : `microphone-${idx}`,
    }));
  } catch (err) {
    console.warn("create audio stream failed!", err);
    throw err;
  } finally {
    agoraAudioStream.close();
  }
};

export class AgoraWebClient {
  public readonly rtc: AgoraRTCClient;
  public shareClient: AgoraRTCClient | any;
  public localUid: number;
  public channel: string;
  public readonly bus: EventEmitter;
  public shared: boolean;
  public joined: boolean;
  public cameraCanUse: boolean;
  public microphoneCanUse: boolean;
  public forceGetDevice: boolean;
  public published: boolean;
  public tmpStream: any;
  public eventRoomStore: any;

  // private eventRoomStore: EventRoomStore;

  constructor(deps?: { eventRoomStore: any }) {
    this.localUid = 0;
    this.channel = "";
    this.bus = new EventEmitter();
    this.shared = false;
    this.tmpStream = null;
    this.joined = false;
    this.published = false;
    this.shareClient = new AgoraRTCClient({
      eventRoomStore: this.eventRoomStore,
    });

    this.cameraCanUse = false;
    this.microphoneCanUse = false;
    this.forceGetDevice = false;

    this.eventRoomStore = deps?.eventRoomStore;
    this.rtc = new AgoraRTCClient({ eventRoomStore: this.eventRoomStore });
  }

  async getDevices() {
    const client = new AgoraRTCClient({ eventRoomStore: this.eventRoomStore });
    let deviceResult: DeviceInfo = {
      cameraCanUse: this.cameraCanUse,
      microphoneCanUse: this.microphoneCanUse,
      devices: [],
    };
    try {
      if (!this.forceGetDevice) {
        deviceResult = await client.forceGetDevices();
        this.forceGetDevice = true;
      } else {
        deviceResult = await client.getDevices();
      }
      const { cameraCanUse, microphoneCanUse, devices } = deviceResult;

      this.cameraCanUse = cameraCanUse;
      this.microphoneCanUse = microphoneCanUse;

      const cameraList = devices.filter((it: any) => it.kind === "videoinput");
      const microphoneList = devices.filter(
        (it: any) => it.kind === "audioinput"
      );

      if (!cameraList.length) {
        console.warn("cameraList is empty", devices);
      }

      if (!microphoneList.length) {
        console.warn("microphoneList is empty", devices);
      }
      return devices;
    } catch (err) {
      throw err;
    } finally {
      // client.destroyLocalStream()
    }
  }

  async joinChannel({
    uid,
    channel,
    dual,
    token,
    appId,
    role,
  }: {
    uid: number;
    channel: string;
    dual: boolean;
    token: string;
    appId: string;
    role: string;
  }) {
    this.localUid = +uid;
    this.channel = channel;
    //await this.rtc.createClient(appId, true);
    try {
      if (this.eventRoomStore._state.me.info.role == "invitee") {
        await this.rtc.setClientRole("audience");
      } else {
        await this.rtc.setClientRole(`${role}`);
      }
      const userId = await this.rtc.join(appId, token, channel, this.localUid);
      dual && (await this.rtc.enableDualStream());
      this.joined = true;
      return { userId };
    } catch (err) {
      console.warn("Stream Published failed", err, JSON.stringify(err));
      return err;
    }
  }

  async leaveChannel() {
    this.localUid = 0;
    this.channel = "";
    try {
      //  await this.unpublishLocalStream();
      await this.rtc.leave();
      // await this.unpublishLocalStream();
      this.joined = false;
      // eventRoomStore.setRTCJoined(false);
    } catch (err) {
      throw err;
    } finally {
      this.rtc.destroy();
      this.rtc.destroyClient();
    }
  }

  async volumeHighlights() {
    try {
      await this.rtc.initVolumeIndicator();
      console.log('Volume indicator initialized successfully');
    } catch (err) {
      console.error('Failed to initialize volume indicator', err);
    }
  }

  async enableDualStream() {
    return this.rtc.enableDualStream();
  }

  async publishLocalStream(data: AgoraStreamSpec) {
    try {
      if (this.published) {
        await this.unpublishLocalStream();
      }
      const devices = await this.getDevices();
      const videoInputs = devices.find(
        (it: any) => it.kind === "videoinput"
      ) as any;
      const audioInputs = devices.find(
        (it: any) => it.kind === "audioinput"
      ) as any;

      if (data.cameraId && videoInputs && videoInputs.length > 0) {
        if (
          videoInputs.findIndex((cam: any) => cam.deviceId == data.cameraId) ==
          -1
        ) {
          data.cameraId = null;
        }
      }

      if (data.microphoneId && audioInputs && audioInputs.length > 0) {
        if (
          audioInputs.findIndex(
            (audio: any) => audio.deviceId == data.microphoneId
          ) == -1
        ) {
          data.microphoneId = null;
        }
      }

      if (!data.cameraId || !data.microphoneId) {
        //let devices = await this.getDevices();
        if (!data.cameraId) {
          // const videoInputs = devices.find(
          //   (it: any) => it.kind === "videoinput"
          // );
          data.cameraId = videoInputs && videoInputs.deviceId;
          if (!data.cameraId) {
            data.video = false;
          }
        }
        if (!data.microphoneId) {
          // const audioInputs = devices.find(
          //   (it: any) => it.kind === "audioinput"
          // );
          data.microphoneId = audioInputs && audioInputs.deviceId;
          if (!data.microphoneId) {
            data.audio = false;
          }
        }
      }
      if (data.video || data.audio) {
        const localStream = await this.rtc.createLocalStream(data);
        // Play the local stream as soon as its created
        if (localStream) {
          let localAudioTrack = localStream[0];
          let localVideoTrack = localStream[1];
          const _stream = new AgoraStream(
            { audioTrack: localAudioTrack, videoTrack: localVideoTrack },
            this.eventRoomStore._state.me.uid,
            true,
            this.eventRoomStore._state.me.info.role == "host"
            || this.eventRoomStore._state.me.info.role == "admin"
              ? false
              : localAudioTrack != null
              ? localAudioTrack.enabled
              : false,
            this.eventRoomStore._state.me.info.role == "host"
            || this.eventRoomStore._state.me.info.role == "admin"
              ? false
              : localVideoTrack.enabled,
            this.eventRoomStore._state.me.info.name,
            this.eventRoomStore._state.me.info.profile_pic,
            this.eventRoomStore._state.me.info.userRole
          );
          this.eventRoomStore.addRTCUser(this.eventRoomStore._state.me.uid);
          this.eventRoomStore.addLocalStream(_stream);
          await this.rtc.publish();
          this.published = true;
          this.eventRoomStore.handleDeviceChange();
        }
      }
    } catch (err) {}
  }

  async unpublishLocalStream() {
    await this.rtc.unpublish();
    this.published = false;
  }

  async startScreenShare({
    uid,
    channel,
    token,
    appId,
  }: {
    uid: number;
    channel: string;
    token: string;
    appId: string;
  }) {
    console.log("startScreenShare ", uid, channel, token, appId);
    try {
      //this.rtc.subscribeLocalStreamEvents();
      let track = await this.shareClient.createScreenShareStream({
        video: false,
        audio: false,
        screen: true,
        screenAudio: true,
        streamID: uid,
        microphoneId: "",
        cameraId: "",
      });
      if (track) {
        track = Array.isArray(track) ? track[0] : track;
        track.on("track-ended", () => {
          console.log("track-ended");
          this.stopScreenShare();
        });
      }
      //await shareClient.createClient(appId);
      await this.shareClient.setClientRole("host");
      await this.shareClient.join(appId, token, channel, uid);
      await this.shareClient.publish();
      this.shared = true;
    } catch (err) {
      throw err;
    }
  }

  async stopScreenShare() {
    this.shared = false;
    await this.shareClient.unpublish("screenShare");
    this.shareClient.destroy("screenShare");
    this.shareClient.destroyClient();
    await this.shareClient.leave();
    this.shared = false;
    this.shareClient = undefined;
  }

  async exit() {
    const errors: any[] = [];
    try {
      await this.leaveChannel();
    } catch (err) {
      errors.push({ rtcClient: err });
    }
    if (this.shared === true) {
      try {
        await this.shareClient.unpublish("screenShare");
        await this.shareClient.leave();
      } catch (err) {
        errors.push({ shareClient: err });
      }
    }
    if (this.shareClient) {
      try {
        this.shareClient.destroy();
        this.shareClient.destroyClient();
      } catch (err) {
        errors.push({ shareClient: err });
      }
    }
    if (!isEmpty(errors)) {
      throw errors;
    }
  }

  async createPreviewStream({ cameraId, microphoneId, speakerId }: any) {
    const tmpStream = AgoraRTC.createStream({
      video: true,
      audio: true,
      screen: false,
      cameraId,
      microphoneId,
      speakerId,
    });

    if (this.tmpStream) {
      this.tmpStream.isPlaying() && this.tmpStream.stop();
      this.tmpStream.close();
    }

    return new Promise((resolve, reject) => {
      tmpStream.init(
        () => {
          this.tmpStream = tmpStream;
          resolve(tmpStream);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  // subscribe(stream: any) {
  //   this.rtc.subscribe(stream);
  // }

  async subscribe(remoteUser: any, mediaType: any) {
    return await this.rtc.subscribe(remoteUser, mediaType);
  }

  setRemoteVideoStreamType(stream: any, type: number) {
    this.rtc.setRemoteVideoStreamType(stream, type);
  }
}
