import { AgoraWebClient } from "@/utils/agora-web-client";
import { eventRoomStore as globleventroomStore } from "@/stores/event";
import {
  MainLiveStreamId,
  INJECT_STREAM_ID,
  SECOND_INJECT_STREAM_ID,
  SCREEN_SHARE_STREAM_ID,
} from "@/utils/config";
import { useEventRoomState } from "@/containers/root-container";

export default function useSubscribeRTCEvent({
  eventRoomStore,
}: any = globleventroomStore) {
  // const eventRoomState = useEventRoomState();
  const subscribeRTCEvent = (webClient: AgoraWebClient) => {
    webClient.rtc.on("user-joined", async ([remoteUser]) => {
      const streamID = remoteUser.uid;
      let tempRemoteStream = {
        audioTrack: null,
        videoTrack: null,
      } as any;

      const userRole = eventRoomStore.getUserRoleById(streamID);
      const details = {
        name:
          userRole == "multiconnection"
            ? await eventRoomStore.fetchUserDetails(
                eventRoomStore.state.me.channelName,
                streamID
              )
            : eventRoomStore.getUserNameById(streamID),
      };

      eventRoomStore.addRemoteStreamDetails(
        streamID,
        tempRemoteStream,
        details,
        userRole
      );
    });

    const takeScreenshot = async () => {
    if (eventRoomStore._state.rtc.localStream && eventRoomStore._state.rtc.localStream.stream && eventRoomStore._state.rtc.localStream.stream.videoTrack) {
      console.log(eventRoomStore._state.rtc.localStream.stream.videoTrack);
      if (eventRoomStore._state.rtc.localStream.stream.videoTrack.isPlaying) {
        const imageData = eventRoomStore._state.rtc.localStream.stream.videoTrack.getCurrentFrameData();
        if (imageData) {
          const canvas = document.createElement("canvas");
          canvas.width = imageData.width;
          canvas.height = imageData.height;
          const context = canvas.getContext("2d");
          if (context) {
            const imageDataObj = new ImageData(
              new Uint8ClampedArray(imageData.data),
              imageData.width,
              imageData.height
            );
  
            context.putImageData(imageDataObj, 0, 0);
            const url = canvas.toDataURL();
            console.log("Screenshot URL:" + url);
            // eventRoomStore._state.screenshotURL = url;
          } else {
            console.log("Failed to get 2D context for canvas.");
          }
        } else {
          console.log("No image data available from videoTrack.");
        }
      } else {
        console.log("Video track is not playing, cannot take screenshot.");
      }
    } else {
      console.log("No videoTrack found in guideStream.");
    }
  };


  webClient.rtc.on("connection-state-change", ([curState, revState]) => {
    console.log('Screenshot not taken successfully' + curState + " " + revState);
    if (curState == "RECONNECTING" && revState == "CONNECTED") {
      // console.log("Screenshot taken successfully.");
      takeScreenshot();
    }
  });

    webClient.rtc.on("user-left", ([user, reason]) => {
      handleStreamRemove(user.uid);
    });

    webClient.rtc.on("user-published", async ([remoteUser, mediaType]) => {
      const streamID = remoteUser.uid;
      //if (streamID !== eventRoomStore.state.me.uid) {
      await webClient.subscribe(remoteUser, mediaType);
      let streamTrack =
        mediaType === "video" ? remoteUser.videoTrack : remoteUser.audioTrack;
      let streamTrackType = mediaType === "video" ? "videoTrack" : "audioTrack";
      const userRole = eventRoomStore.getUserRoleById(streamID);
      const details = {
        name:
          userRole == "multiconnection"
            ? await eventRoomStore.fetchUserDetails(
                eventRoomStore.state.me.channelName,
                streamID
              )
            : eventRoomStore.getUserNameById(streamID),
      };
      eventRoomStore.updateRemoteDetails(
        streamTrackType,
        streamID,
        streamTrack,
        details
      );
      //});
    });
    webClient.rtc.on("user-unpublished", ([remoteUser, mediaType]) => {
      eventRoomStore.mute(remoteUser.uid, mediaType);
    });
  };

  const handleStreamRemove = (streamID: number) => {
    const pinid = Number(sessionStorage.getItem("pinid"));
    if (
      eventRoomStore.state.rtc.injectedStream &&
      +streamID === +eventRoomStore.state.rtc.injectedStream.streamID
    ) {
      eventRoomStore.removeInjectedStream();
    } else {
      eventRoomStore.removePeerUser(streamID);
      eventRoomStore.removeRemoteStream(streamID);
    }
    if (streamID === eventRoomStore.state.me.mainLiveStreamID) {
      eventRoomStore.updateLocalMe({
        mainLiveStreamID: 0,
      });
    }
    if (streamID === SCREEN_SHARE_STREAM_ID) {
      const grid = sessionStorage.getItem("grid");
      if (grid === "true") {
        eventRoomStore.updateGridView(true);
      } else {
        eventRoomStore.updateGridView(false);
      }
    }

    if (streamID === pinid) {
      // sessionStorage.setItem("pinid", "0");
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
    }
  };

  return {
    subscribeRTCEvent,
  };
}
